"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.detectFlexGapSupported = exports.isStyleSupport = exports.canUseDocElement = void 0;

var _canUseDom = _interopRequireDefault(require("rc-util/lib/Dom/canUseDom"));

var canUseDocElement = function canUseDocElement() {
  return (0, _canUseDom["default"])() && window.document.documentElement;
};

exports.canUseDocElement = canUseDocElement;

var isStyleSupport = function isStyleSupport(styleName) {
  if (canUseDocElement()) {
    var styleNameList = Array.isArray(styleName) ? styleName : [styleName];
    var documentElement = window.document.documentElement;
    return styleNameList.some(function (name) {
      return name in documentElement.style;
    });
  }

  return false;
};

exports.isStyleSupport = isStyleSupport;
var flexGapSupported;

var detectFlexGapSupported = function detectFlexGapSupported() {
  if (!canUseDocElement()) {
    return false;
  }

  if (flexGapSupported !== undefined) {
    return flexGapSupported;
  } // create flex container with row-gap set


  var flex = document.createElement('div');
  flex.style.display = 'flex';
  flex.style.flexDirection = 'column';
  flex.style.rowGap = '1px'; // create two, elements inside it

  flex.appendChild(document.createElement('div'));
  flex.appendChild(document.createElement('div')); // append to the DOM (needed to obtain scrollHeight)

  document.body.appendChild(flex);
  flexGapSupported = flex.scrollHeight === 1; // flex container should be 1px high from the row-gap

  document.body.removeChild(flex);
  return flexGapSupported;
};

exports.detectFlexGapSupported = detectFlexGapSupported;